@import "src/theme/variables.scss";

.paragraph {
  text-overflow: ellipsis;
  overflow-x: inherit;
  line-height: 1.38;
  font-family: Inter;
}

.gradient {
  --gradient-color-1: #5EE6D0;
  --gradient-color-2: #BFC6FF;
  --gradient-color-3: #FFBA69;

  background: linear-gradient(108.46deg, var(--gradient-color-1) 12.51%, var(--gradient-color-2) 51.13%, var(--gradient-color-3) 87.49%);

  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  width: -moz-fit-content;
  width: fit-content;
}

.gray {
  color: #98989B;
}

.soft {
  color: #000000;
}

.medium {
  color: #686868;
}

.dark {
  color: black;
}

.fancy {
  color: $fancy;
}

.warning {
  color: $warning;
}

.primary {
  color: $fontColor;
}

.green {
  color: $green;
}

.secondary {
  color: $secondary;
}

.disabled {
  // color: $disabled;
  color: #98989B;
}

.greyscale {
  color: 'rgba(152, 152, 155, 1)'
}

.error {
  color: $error;
}

.white {
  color: white;
}

.textaura {
  color: #E6E7E8;
}

.descriptionAura {
  color: #98989B;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.uppercase {
  text-transform: uppercase;
}

.noMargin {
  margin: 0;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.xs {
  font-size: $extraSmallFontSize;
}

.sm {
  font-size: $smallFontSize;
}

.bmd {
  font-size: $bmg;
}

.smd {
  font-size: $smd;
}

.md {
  font-size: $mediumFontSize;
}

.lg {
  font-size: $largeFontSize;
}

.xl {
  font-size: $extraLargeFontSize;
}

.xxl {
  font-size: $xxlFontSize;
}

.light {
  font-weight: $lightFont;
}

.regular {
  font-weight: $regularFont;
}

.semiBold {
  font-weight: $semiBoldFont;
}

.bolder {
  font-weight: $mediumFont;
}

.bold {
  font-weight: $boldFont;
}

.dot {
  display: list-item;
}
