@import "src/theme/variables.scss";

.link {
  text-decoration: none;
}

.regular {
  color: $secondary;
}

.white {
  color: white;
}

.paddingXs {
  padding-right: $xs;
}

.paddingSm {
  padding-right: $sm;
}

.paddingMd {
  padding-right: $md;
}