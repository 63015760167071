@import 'src/theme/variables.scss';
html,
body {
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: SFProDisplay;
  src: url('../../assets/fonts/SfProDisplay/SFPRODISPLAYREGULAR.otf');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: SFProDisplay;
  src: url('../../assets/fonts/SfProDisplay/SFPRODISPLAYMEDIUM.otf');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: SFProDisplay;
  src: url('../../assets/fonts/SfProDisplay/SFPRODISPLAYBOLD.otf');
  font-style: normal;
  font-weight: 700;
}

/* italic */

@font-face {
  font-family: SFProDisplay;
  src: url('../../assets/fonts/SfProDisplay/SFPRODISPLAYTHINITALIC.otf');
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: SFProDisplay;
  src: url('../../assets/fonts/SfProDisplay/SFPRODISPLAYULTRALIGHTITALIC.otf');
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: SFProDisplay;
  src: url('../../assets/fonts/SfProDisplay/SFPRODISPLAYLIGHTITALIC.otf');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: SFProDisplay;
  src: url('../../assets/fonts/SfProDisplay/SFPRODISPLAYSEMIBOLDITALIC.otf');
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: SFProDisplay;
  src: url('../../assets/fonts/SfProDisplay/SFPRODISPLAYBLACKITALIC.otf');
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: SFProDisplay;
  src: url('../../assets/fonts/SfProDisplay/SFPRODISPLAYHEAVYITALIC.otf');
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: Inter;
  src: url('../../assets/fonts/Inter/Inter-Thin.ttf');
  font-weight: 100;
}
@font-face {
  font-family: Inter;
  src: url('../../assets/fonts/Inter/Inter-ExtraLight.ttf');
  font-weight: 200;
}
@font-face {
  font-family: Inter;
  src: url('../../assets/fonts/Inter/Inter-Light.ttf');
  font-weight: 300;
}
@font-face {
  font-family: Inter;
  src: url('../../assets/fonts/Inter/Inter-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: Inter;
  src: url('../../assets/fonts/Inter/Inter-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: Inter;
  src: url('../../assets/fonts/Inter/Inter-SemiBold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: Inter;
  src: url('../../assets/fonts/Inter/Inter-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: Inter;
  src: url('../../assets/fonts/Inter/Inter-ExtraBold.ttf');
  font-weight: 800;
}
@font-face {
  font-family: Inter;
  src: url('../../assets/fonts/Inter/Inter-Black.ttf');
  font-weight: 900;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: $background;
  bottom: 0;
  color: $fontColor;
  font-family: 'Inter', monospace;
  font-size: $mediumFontSize;
  left: 0;
  margin: 0;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  text-rendering: geometricPrecision;
  top: 0;
}

body > div:first-child {
  display: flex;
  min-height: 100%;
}
/*Aura custom scollbar*/
/* width */
::-webkit-scrollbar {
  width: 4px;
  background: rgba(54, 56, 67, 0.5);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #868A97;
  border-radius: 2px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
.error-msg {
  color: #f56161;
  font-size: 12px;
  margin: 6px 0px 0px;
}