$background: #f7f5f5;
$border: #e8e7e6;
$connectedColor: #03a9f4;
$disabled: #5D6D74;
$error: #f02525;
$fancy: #f02525;
$fontColor: #FFFFFF;
$primary: #777E91;
$secondary: #03a9f4;
$secondaryTextOrSvg: #B2B5B2;
$secondaryBackground: #f0efee;
$warning: #ffc05f;
$fancy: #f02525;
$secondary: #03a9f4;
$green: #5EE6D0;


$headerHeight: 52px;
$marginButtonImg: 12px;

$lg: 24px;
$md: 16px;
$bmg: 20px;
$sm: 8px;
$xl: 32px;
$xs: 4px;
$xxl: 40px;
$smd: 14px;


$mediumFont: 500;
$lightFont: 300;
$regularFont: 400;
$semiBoldFont: 590;
$boldFont: 700;
$extraBoldFont: 800;

$fontSizeHeadingLg: 32px;
$fontSizeHeadingMd: 20px;
$fontSizeHeadingSm: 16px;
$fontSizeHeadingXs: 13px;

$mediumFontSize: 14px;
$xxlFontSize: 32px;
$smallFontSize: 12px;
$largeFontSize: 16px;
$extraLargeFontSize: 20px;
$extraSmallFontSize: 11px;



$screenLg: 1200;
$screenMd: 992;
$screenSm: 768;
$screenXs: 480;