/* Onboard.js custom styles */

:global(.bn-onboard-custom.bn-onboard-modal) {
  font-family: 'Inter !important';
  z-index: 1298;
}

:global(.torusIframe) {
  z-index: 9999;
}
